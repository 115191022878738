.App {
    height: 100vh;
    width: 100vw;
}

.app-logo {
    width: 20%;
    height: auto;
    pointer-events: none;
    background: var(--background-color);
}

.app-header {
    display: flex;
    position: relative;
    padding: 2%;
}

.app-header .app-logo {
    margin-right: 3%;
}

.app-header .app-controls {
    position: absolute;
    top: 1%;
    right: 1%;
}

.app-header .app-controls .app-control {
    display: flex;
}

.app-header .app-controls .app-control .fa-sun {
    display: none;
}

.dark-mode .app-header .app-controls .app-control .fa-sun {
    display: initial;
}

.dark-mode .app-header .app-controls .app-control .fa-moon {
    display: none;
}

footer {
    font-size: 65%;
    width: 95%;
    margin: 10px auto;
}