.UserList {
    padding: 1%;
    width: 20%;
}

.UserList .user-list-container {
    max-height: 70%;
    overflow-y: scroll;
    border: 1px dotted var(--foreground-color);
}

.dark-mode .UserList .user-list-container {
    border-color: 1px dotted var(--dark-mode-foreground-color);
}

.UserList .user-list-container .user-line {
    cursor: pointer;
    line-height: 1.2em;
    padding: 3px;
    transition: background 0.25s, color 0.25s;
}

.UserList .user-list-container .user-line.selected {
    border: 1px solid var(--foreground-color);
    background-color: var(--input-hover-color);
}

.dark-mode .UserList .user-list-container .user-line.selected {
    border: 1px solid var(--dark-mode-foreground-color);
    background-color: var(--dark-mode-input-hover-color);
}

.UserList .user-list-container .user-line.no {
    color: red;
}

.UserList .user-list-container .user-line:hover {
    background-color: var(--list-hover-background-color);
}

.dark-mode .UserList .user-list-container .user-line:hover {
    background-color: var(--dark-mode-list-hover-background-color);
}