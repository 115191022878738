@keyframes fa-spinner-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ProjectList {
    padding: 1%;
    width: 20%;
}

.ProjectList .fa-spinner {
    animation: fa-spinner-spin 1s infinite linear;
}

.ProjectList .project-list-container {
    max-height: 70%;
    overflow-y: scroll;
    border: 1px dotted var(--foreground-color);
}

.dark-mode .ProjectList .project-list-container {
    border-color: var(--dark-mode-foreground-color);
}

.ProjectList .project-list-container .project-line {
    cursor: pointer;
    line-height: 1.2em;
    padding: 3px;
    transition: background 0.25s, color 0.25s;
}

.ProjectList .project-list-container .project-line.selected {
    border: 1px solid var(--foreground-color);
    background-color: var(--input-hover-color);
}

.dark-mode .ProjectList .project-list-container .project-line.selected {
    border-color: var(--dark-mode-foreground-color);
    background-color: var(--dark-mode-input-hover-color);
}

.ProjectList .project-list-container .project-line.no {
    color: red;
}

.ProjectList .project-list-container .project-line:hover {
    background-color: var(--list-hover-background-color);
}

.dark-mode .ProjectList .project-list-container .project-line:hover {
    background-color: var(--dark-mode-list-hover-background-color);
}