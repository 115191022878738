.ProjectDetails {
    width: 75%;
    border-left: 1px solid var(--foreground-color);
    padding: 1%;
}

.dark-mode .ProjectDetails {
    border-color: var(--dark-mode-foreground-color);
}

.ProjectDetails .project-name {
    height: 1.33em;
    overflow: hidden;
}

.ProjectDetails label {
    font-weight: bold;
}

.ProjectDetails .info-container {
    display: flex;
    height: 85%;
}

.ProjectDetails .overview-pane,
.ProjectDetails .database-pane {
    border: 1px solid var(--foreground-color);
    padding: 1%;
}

.dark-mode .ProjectDetails .overview-pane,
.dark-mode .ProjectDetails .database-pane {
    border-color: var(--dark-mode-foreground-color);
}

.ProjectDetails .overview-pane {
    width: 50%;
}

.ProjectDetails .database-pane {
    width: 50%;
}

.ProjectDetails .info-container .password {
    display: flex;
    flex-direction: row;
}

.ProjectDetails .info-container .password .password-display,
.ProjectDetails .info-container .phpversion .phpversion-display {
    border-bottom: 1px dotted var(--foreground-color);
    transition: background-color 0.25s;
    margin-left: 9px;
}

.dark-mode .ProjectDetails .info-container .password .password-display,
.dark-mode .ProjectDetails .info-container .phpversion .phpversion-display {
    border-bottom-color: var(--dark-mode-foreground-color);
}

.ProjectDetails .info-container .password .password-display:hover,
.ProjectDetails .info-container .phpversion .phpversion-display:hover {
    background-color: var(--input-hover-color);
}

.dark-mode .ProjectDetails .info-container .password .password-display:hover,
.dark-mode .ProjectDetails .info-container .phpversion .phpversion-display:hover {
    background-color: var(--dark-mode-input-hover-color);
}

.ProjectDetails .info-container .password button,
.ProjectDetails .info-container .phpversion button {
    width: 1em;
    height: 1em;
    margin: 0 2%;
    position: relative;
}

.ProjectDetails .info-container .password button > *,
.ProjectDetails .info-container .phpversion button > * {
    position: absolute;
    top: 0.4em;
    left: 0.5em;
}
.ProjectDetails .info-container .password button:last-child,
.ProjectDetails .info-container .phpversion button:last-child {
    margin-right: 0;
}

.ProjectDetails .info-container .password-change,
.ProjectDetails .info-container .phpversion-change {
    height: 1em;
    max-width: 40%;
}

.ProjectDetails .project-database-container {
    max-height: 100%;
    overflow-y: scroll;
    border: 1px dotted var(--foreground-color);
}

.dark-mode .ProjectDetails .project-database-container {
    border-color: 1px dotted var(--dark-mode-foreground-color);
}

.ProjectDetails .project-database-container .database-line {
    cursor: pointer;
    line-height: 1.2em;
    padding: 3px;
    transition: background 0.25s, color 0.25s;
}

.ProjectDetails .project-database-container .database-line.selected {
    border: 1px solid var(--foreground-color);
    background-color: var(--input-hover-color);
}

.dark-mode .ProjectDetails .project-database-container .database-line.selected {
    border: 1px solid var(--dark-mode-foreground-color);
    background-color: var(--dark-mode-input-hover-color);
}

.ProjectDetails .project-database-container .database-line.no {
    color: red;
}

.ProjectDetails .project-database-container .database-line:hover {
    background-color: var(--list-hover-background-color);
}

.dark-mode .ProjectDetails .project-database-container .database-line:hover {
    background-color: var(--dark-mode-list-hover-background-color);
}