.MainWindow {
    border: 1px solid var(--foreground-color);
    width: 95%;
    margin: auto;
    height: 67%;
}

.dark-mode .MainWindow {
    border-color: var(--dark-mode-foreground-color);
}

.MainWindow .tabs {
    display: flex;
    flex-direction: row;
}

.MainWindow .tabs .tab {
    border: 1px solid var(--foreground-color);
    margin: 2px;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--foreground-color);
    background-color: var(--background-color);
}

.dark-mode .MainWindow .tabs .tab {
    border-color: var(--dark-mode-foreground-color);
    color: var(--dark-mode-foreground-color);
    background-color: var(--dark-mode-background-color);
}

.MainWindow .tabs .tab:hover {
    border-color: var(--button-hover-foreground-color);
    color: var(--button-hover-foreground-color);
    background-color: var(--button-hover-background-color);
}

.dark-mode .MainWindow .tabs .tab:hover {
    border-color: var(--dark-mode-button-hover-foreground-color);
    color: var(--dark-mode-button-hover-foreground-color);
    background-color: var(--dark-mode-button-hover-background-color);
}

.MainWindow .container {
    border: 1px solid var(--foreground-color);
    margin: 5px;
    display: flex;
    height: 85%;
}

.dark-mode .MainWindow .container {
    border-color: var(--dark-mode-foreground-color);
}
