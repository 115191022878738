:root {
  /* font sizes */
  --font-size-std: 24px;
  --font-size-sm: 18px;
  --font-size-lg: 36px;

  /* default colors in light mode */
  --background-color: #eef;
  --foreground-color: #002;
  --input-hover-color: #dde;
  --button-color: blue;
  --button-hover-background-color: #004;
  --button-hover-foreground-color: #22f;
  --list-hover-background-color: #aac;

  /* default colors in dark mode */
  --dark-mode-background-color: #002;
  --dark-mode-foreground-color: #eef;
  --dark-mode-input-hover-color: #223;
  --dark-mode-button-color: #22f;
  --dark-mode-button-hover-background-color: #ccf;
  --dark-mode-button-hover-foreground-color: #004;
  --dark-mode-list-hover-background-color: #446;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-std);
  background: var(--background-color);
  color: var(--foreground-color);
  transition: background 0.25s, color 0.25s;
}

body.dark-mode {
  background: var(--dark-mode-background-color);
  color: var(--dark-mode-foreground-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
select {
  font-size: var(--font-size-std);
  margin: 5px;
  border: none;
  border-bottom: 1px dotted var(--foreground-color);
  background: var(--background-color);
  color: var(--foreground-color);
  transition: background 0.25s;
}

input:hover,
select:hover {
  background: var(--input-hover-color);
}

.dark-mode input,
.dark-mode select {
  border-bottom: 1px dotted var(--dark-mode-foreground-color);
  background: var(--dark-mode-background-color);
  color: var(--dark-mode-foreground-color);
}

.dark-mode input:hover,
.dark-mode select:hover {
  background: var(--dark-mode-input-hover-color);
}

option {
  background: var(--background-color);
  color: var(--foreground-color);
}

.dark-mode option {
  background: var(--dark-mode-background-color);
  color: var(--dark-mode-foreground-color);
}

button {
  cursor: pointer;
  height: 100%;
  padding: 15px;
  margin: 5px;
  font-size: var(--font-size-sm);
  font-weight: bold;
  text-transform: uppercase;
  background: var(--background-color);
  border: 1px solid var(--button-color);
  color: var(--button-color);
  transition: background 0.25s, color 0.25s;
}

button:hover {
  border: 1px solid var(--button-hover-foreground-color);
  background: var(--button-hover-background-color);
  color: var(--button-hover-foreground-color);
}

.dark-mode button {
  border: 1px solid var(--dark-mode-button-color);
  background: var(--dark-mode-background-color);
  color: var(--dark-mode-button-color);
}

.dark-mode button:hover {
  border: 1px solid var(--dark-mode-button-color);
  background: var(--dark-mode-button-hover-background-color);
  color: var(--dark-mode-button-hover-foreground-color);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.label {
  font-weight: bold;
}

.description {
  font-size: 75%;
}

.modal {
  position: absolute;
  left: 30%;
  top: 25%;
  width: 38%;
  padding: 1%;
  border: 3px solid var(--foreground-color);
  background-color: var(--background-color);
}

.dark-mode .modal {
  border-color: var(--dark-mode-foreground-color);
  background-color: var(--dark-mode-background-color);
}

.modal .close-window {
  color: var(--foreground-color);
  border: none;
  position: absolute;
  right: 1%;
  height: 20px;
  width: 20px;
}

.dark-mode .modal .close-window {
  color: var(--dark-mode-foreground-color);
}

.modal .close-window:hover {
  color: var(--button-hover-foreground-color);
  background-color: var(--button-hover-background-color);
}

.dark-mode .modal .close-window:hover {
  color: var(--dark-mode-button-hover-foreground-color);
  background-color: var(--dark-mode-button-hover-background-color);
}

.modal .close-window > * {
  position: absolute;
  top: 6px;
  left: 10px;
}

.modal .modal-title {
  margin-bottom: 0.75em;
}

.modal .row {
  margin-bottom: 0.5em;
}

.modal .actions {
  text-align: center;
}

.modal .actions button {
  width: 25%;
}