.UserDetails {
    width: 75%;
    border-left: 1px solid var(--foreground-color);
    padding: 1%;
}

.dark-mode .UserDetails {
    border-color: var(--dark-mode-foreground-color);
}

.UserDetails .user-name {
    height: 1.33em;
    overflow: hidden;
}

.UserDetails label {
    font-weight: bold;
}

.UserDetails .info-container {
    display: flex;
    flex-direction: column;
    height: 85%;
}

.UserDetails .overview {
    border: 1px solid var(--foreground-color);
    padding: 1%;
}

.dark-mode .UserDetails .overview {
    border-color: var(--dark-mode-foreground-color);
}

.UserDetails .overview {
    width: 50%;
}

.UserDetails .info-container .password {
    display: flex;
    flex-direction: row;
}

.UserDetails .info-container .password .password-display {
    border-bottom: 1px dotted var(--foreground-color);
    transition: background-color 0.25s;
    margin-left: 9px;
}

.dark-mode .UserDetails .info-container .password .password-display {
    border-bottom-color: var(--dark-mode-foreground-color);
}

.UserDetails .info-container .password .password-display:hover {
    background-color: var(--input-hover-color);
}

.dark-mode .UserDetails .info-container .password .password-display:hover {
    background-color: var(--dark-mode-input-hover-color);
}

.UserDetails .info-container .password button {
    width: 1em;
    height: 1em;
    margin: 0 2%;
    position: relative;
}

.UserDetails .info-container .password button > * {
    position: absolute;
    top: 0.4em;
    left: 0.5em;
}
.UserDetails .info-container .password button:last-child {
    margin-right: 0;
}

.UserDetails .info-container .password-change {
    height: 1em;
    max-width: 40%;
}
