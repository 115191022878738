.Auth {
    position: relative;
}

.Auth .login {
    margin: 5% auto;
    width: 35%;
    border: 1px solid var(--foreground-color);
    padding: 0 2% 2%;
}

.dark-mode .Auth .login {
    border-color: var(--dark-mode-foreground-color);
}

.Auth .login .form-fields {
    display: flex;
    flex-direction: column;
}

.Auth .login .form-fields label svg {
    width: 7.5%;
}

.Auth .login .form-fields input {
    margin: 1vh 0;
}

.Auth .login .form-fields .action-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
}

.Auth .login .form-fields .action-buttons button {
    width: 45%;
    margin-right: 10%;
}

.Auth .login .form-fields .action-buttons button:last-child {
    margin-right: 0;
}

.Auth .login .login-message {
    margin-top: 1%;
    color: red;
}

.Auth .user-info-container {
    position: absolute;
    right: 3%;
    width: 20%;
    top: -4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.Auth .user-info-container .user-info {
    margin-right: 5%;
}